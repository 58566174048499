<template>
  <div class="mx-auto ToolSettings">
    <div class="ToolSettings__settings-tab">

      <v-text-field
        outlined
        dense
        :value="Tool.name"
        @input="(val) => updateTool('name', val)"
        :rules="nameRules"
        label="Name"
        class="mb-3"
      />

      <v-textarea
          outlined
          dense
          :value="Tool.description"
          @input="(val) => updateTool('description', val)"
          label="Description for AI"
          class="mb-3"
      />

      <v-autocomplete
          class="mb-3"
          outlined
          dense
          :value="Tool.workflow_id"
          @input="(val) => updateTool('workflow_id',val)"
          item-text="name"
          item-value="id"
          :items="workflows"
          label="Workflow as Tool"
          :append-outer-icon="loading ? 'mdi-loading mdi-spin' : 'mdi-reload'"
          @click:append-outer="listWorkflows"
          clearable></v-autocomplete>

      <v-divider class="mx-n5 ToolSettings__divider" />
      <section>
        <p class="text-h6 pt-4">Input schema</p>
        <div class="input_schema">
          <JsonEditorVue :mode="'text'" class="input_schema" v-model="Tool.input_schema"/>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import cloneDeep from 'lodash/cloneDeep';
import lodashSet from 'lodash/set';
import JsonEditorVue from "json-editor-vue";

const { mapGetters: ToolGetters, mapMutations: ToolMutations } =
  createNamespacedHelpers('promptsTools');

const { mapGetters: WorkflowGetters, mapActions: WorkflowActions } =
    createNamespacedHelpers('workflows/list');
  
export default {
  name: 'ToolSettings',
  components: {JsonEditorVue},
  data() {
    return {
      /*types: [
        {
          label: 'Function',
          value: 'function'
        },
        {
          label: 'Print',
          value: 'print'
        },
      ],
      selectedType: {
        label: 'Function',
        value: 'function'
      },*/
      nameRules: [v => !!v || 'Name is required',
      v => /^[a-zA-Z][a-zA-Z0-9_]*$/.test(v)||`Name does not match pattern. Only letters and numbers are allowed, no spaces.`],
      tab: 0,
      videoDialog: false,
      videoLinks: [],
    };
  },
  computed: {
    ...ToolGetters({
      Tool: 'TOOL',
    }),
    ...WorkflowGetters({
      workflows: 'AUTOMATION_WORKFLOWS_NAMES',
      loading: 'FETCH_AUTOMATION_WORKFLOWS_NAMES_LOADING'
    })
  },
  methods: {
    ...ToolMutations({
      setTool: 'SET_TOOL',
    }),
    ...WorkflowActions({
      fetchWorkflows: 'fetchWorkflowsNames'
    }),
    async updateTool(prop, value)
    {
      const Tool = cloneDeep(this.Tool);
      lodashSet(Tool, prop, value);
      this.setTool(Tool);
    },
    async listWorkflows() {
      await this.fetchWorkflows({ customer_id: this.Tool.customer_id});
    }
  },
  async mounted() {
    await this.listWorkflows();
  },
  async created() {
    await this.listWorkflows();
  }
};
</script>

<style lang="scss" scoped>
@import './tool-settings';
</style>


